import {fetch_retry} from "../../helpers/requests";


export class ParsingItemsDisplay {

    constructor(geoJsonPath) {
    }

    popupLoadCallback() {
        return async function (properties) {
            let parsingItemID = properties.id;
            console.log('parsingItemID ', parsingItemID);
            let url = '/monitor/parsing-items/' + parsingItemID + '/map-info/';
            let options = {
                method: "GET",
                credentials: 'include',
            };
            return fetch_retry(url, options, 2)
                .then(response => response.text());
        }
    }
}
