export class DrrpNumbersDisplay {

    constructor() {
    }

    popupLoadCallback() {
        return async function (properties) {
            let area = properties.area;
            let number = properties.number;

            return `
                <div>
                    <h3>
                        ${number}
                    </h3>
                    <p class="area">Площа: ${area}</p>
                </div>
            `;
        }
    }
}
