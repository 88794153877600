export class MapboxLayersControl {

    constructor(layers) {
        this.layers = layers;
    }

    onAdd(map) {
        this.map = map;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
        this.createLayers();
        return this.container;
    }

    onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }

    createLayers() {
        for (let i = 0; i < this.layers.length; i++) {
            this.map.addLayer(this.layers[i]);
            let checkbox = createCheckbox(
                this.layers[i].id,
                this.layers[i].name,
                this.layers[i].name,
                this.layers[i].layout.visibility === 'visible',
                this.onclick(),
            );
            this.container.appendChild(checkbox)
        }
    }

    onclick() {
        let self = this;
        return function () {
            let value = this.checked ? 'visible' : 'none';
            self.map.setLayoutProperty(this.name, 'visibility', value);
        }
    }
}

function createCheckbox(name, value, displayLabel, checked, onclick) {
    let div = document.createElement('div');
    div.className = 'mapbox-ctrl-layers-layer';

    let checkbox = document.createElement('input');
    checkbox.type = "checkbox";
    checkbox.id = name;
    checkbox.name = name;
    checkbox.value = value;
    checkbox.checked = checked;
    checkbox.onclick = onclick;

    let label = document.createElement('label');
    label.setAttribute('for', name);
    label.innerText = displayLabel;

    div.appendChild(checkbox);
    div.appendChild(label);
    return div
}
