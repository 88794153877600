export class GeoCoordHelper {

    toMetricalPolygon(polygon) {
        let metricalPolygon = [];
        for (let i = 0; i < polygon.length; i++) {
            metricalPolygon.push(this.coordToMercator(polygon[i][0], polygon[i][1]));
        }
        return metricalPolygon
    }

    coordToMercator(lng, lat) {
        let a = 6378137;
        let b = 6378137;

        let rLat = lat * Math.PI / 180;
        let rLong = lng * Math.PI / 180;
        let f = (a - b) / a;
        let e = Math.sqrt(2 * f - Math.pow(f, 2));
        let X = a * rLong;
        let Y = a * Math.log(Math.tan(Math.PI / 4 + rLat / 2) * Math.pow(((1 - e * Math.sin(rLat)) / (1 + e * Math.sin(rLat))), (e / 2)));
        return [X, Y]
    }
}

export class GeoJsonHelper {

    static featureIsPolygon(geoJson) {
        let feature = geoJson.features[0];
        if (this.isPolygon(feature)) {
            return feature
        }
    }

    static isPolygon(feature) {
        return feature.geometry.type === "Polygon"
    }

    static coordinatesAsPolygon(coordinates) {
        return {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Polygon",
                "coordinates": [coordinates],
            }
        }
    }

    static coordinatesAsMultiPolygon(existing, coordinates) {
        if (existing === null || existing === undefined || existing === "" || existing === "{}") {
            return {
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "MultiPolygon",
                    "coordinates": [
                        [coordinates],
                    ]
                }
            }
        } else {
            let existingFeature = JSON.parse(existing);
            existingFeature['geometry']['coordinates'].push([coordinates]);
            return existingFeature;
        }
    }
}
