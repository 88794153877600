import area from "area-polygon";
import {GeoCoordHelper} from "../../helpers/geo";

let geoHelper = new GeoCoordHelper();


export class CadastralPolygon {
    /**
     * A class to to check area, convert to mercator and generate coordinates to load to check
     **/

    constructor(polygonCoordinates, step = 50) {
        this.polygonCoords = polygonCoordinates;
        this.step = step;
        this.metricalPolygonCoords = geoHelper.toMetricalPolygon(this.polygonCoords);
    }

    getPolygonArea() {
        return area(this.metricalPolygonCoords);
    }
}
