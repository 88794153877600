"use strict";

import "@babel/polyfill";
import * as errors from './errors';

import {MapboxMapProvider} from "./maps/mapbox/mapbox"
import {FeodalMapConfig} from "./config";
import {CadNumPolygonComponent} from "./components/cadNumPolygon/component";
import {userCoordinates} from "./helpers/coord";
import {ParsingItemsDisplay} from "./components/parsingItemsDisplay/component";
import {KmlComponent} from "./components/kml/component";
import {DrrpNumbersDisplay} from "./components/drrpNumberDisplay/component";
import {SurveyingPhotosDisplay} from "./components/surveyingPhotosDisplay/component";
import {NumbersDisplay} from "./components/numbersDisplay/component";

window.FeodalMap = class {

    constructor(config) {
        this.config = new FeodalMapConfig(config);
        this.mapProvider = new MapboxMapProvider(
            this.config.mapboxToken,
            this.config.mapboxOptions,
            this.config.geoLayers,
        );
    }

    getCenter() {
        return this.config.center === undefined ? userCoordinates() : this.config.center;
    }

    init() {
        this.mapProvider.initMap();

        this.mapProvider.setIcons(this.config.icons);

        if (this.config.draw.enable === true) {
            this.drawInit();
        }

        if (this.config.numbersByDrawnPolygon.enable === true) {
            this.polygonDraw();
        }

        if (this.config.geolocation === true) {
            this.mapProvider.useGeolocation();
        }

        if (this.config.search === true) {
            this.mapProvider.addSearch(this.config.searchPhrase);
        }

        if (this.config.kml.enable === true) {
            this.kmlHandling()
        }

        if (this.config.displayCadastralParsingInfo.enable === true) {
            this.addParsingItemsMarkers(this.config.displayCadastralParsingInfo.data, this.config.displayCadastralParsingInfo.layer)
        }

        if (this.config.displayCadastralNumbers.enable === true) {
            this.addNumbersMarkers(this.config.displayCadastralNumbers.data, this.config.displayCadastralNumbers.layer)
        }

        if (this.config.displaySurveyingPhotos.enable === true) {
            this.addSurveyingPhotos(this.config.displaySurveyingPhotos.data, this.config.displaySurveyingPhotos.layer)
        }

        if (this.config.displayDRRPNumbers.enable === true) {
            this.addDRRPNUmbers(this.config.displayDRRPNumbers.data, this.config.displayDRRPNumbers.layer)
        }

        if (this.config.displayPolygon.enable === true) {
            this.addPolygon(
                this.config.displayPolygon.id,
                this.config.displayPolygon.data,
                this.config.displayPolygon.layer,
                this.config.displayPolygon.labelProperty,
            )
        }

    }

    flyTo(coord, zoom = 12) {
        this.mapProvider.flyTo(coord, zoom)
    }

    kmlHandling() {
        new KmlComponent(this.config.kml.inputFieldID)
            .handleInput()
            .then(result => {
                this.mapProvider.addPolygon("kml", "geojson", result);
                let polygon;
                for (let i = 0; i < result.features.length; i++) {
                    let feature = result.features[i];
                    for (let j = 0; j < feature.geometry.coordinates.length; j++) {
                        polygon = feature.geometry.coordinates[j];
                        this.loadNumbersByPolygon(polygon);
                    }
                }
                if (polygon !== undefined) {
                    this.mapProvider.flyTo(polygon[0]);
                }
            })
            .catch(e => {
                swal({type: 'error', text: e.message});
                throw e;
            })
    }

    polygonDraw() {
        let self = this;

        this.mapProvider.drawFeature(undefined, function (e) {
            swal({
                title: "Нова система пошуку кадастрових номерів",
                text: "Після створення групи система автоматично знайде всі кадастрові номери в межах вказаного полігону",
                type: "info",
                confirmButtonText: "Зрозуміло",
                confirmButtonColor: "#ec6c62",
            });
            let coordinates = e.features[0].geometry.coordinates[0];
            self.loadNumbersByPolygon(coordinates);
        });
    }

    addPolygon(id, data, layer, labelProperty) {
        let self = this;
        let addPolygon = function () {
            self.mapProvider.addPolygon(id, "geojson", data, layer, null, labelProperty);
        };
        this.mapProvider.whenMapLoads(addPolygon);
    }

    loadNumbersByPolygon(coordinates) {
        new CadNumPolygonComponent(coordinates, this.config.numbersByDrawnPolygon.numberInputField, this.config.numbersByDrawnPolygon.savePolygonField)
            .load()
            .catch(e => {
                swal({type: 'error', text: e.message});
                if (e instanceof errors.MoreThanOneThousandGAError) {
                    this.mapProvider.trashDrawing();
                }
                throw e;
            })

    }

    addParsingItemsMarkers(geojsonPath, layer = {}) {
        let popupDataCallback = new ParsingItemsDisplay().popupLoadCallback();
        let self = this;

        this.mapProvider.whenMapLoads(function () {
            self.mapProvider.addMarkers('parsing_items', 'geojson', geojsonPath, layer, popupDataCallback)
        })
    }

    addNumbersMarkers(geojsonPath, layer = {}) {
        let popupDataCallback = new NumbersDisplay().popupLoadCallback();
        let self = this;

        this.mapProvider.whenMapLoads(function () {
            self.mapProvider.addMarkers('numbers', 'geojson', geojsonPath, layer, popupDataCallback)
        })
    }

    addSurveyingPhotos(geojsonPath, layer = {}) {
        let popupDataCallback = new SurveyingPhotosDisplay().popupLoadCallback();
        let self = this;

        this.mapProvider.whenMapLoads(function () {
            self.mapProvider.addMarkers('surveying_photos', 'geojson', geojsonPath, layer, popupDataCallback)
        })
    }

    addDRRPNUmbers(geojsonPath, layer = {}) {
        let popupDataCallback = new DrrpNumbersDisplay().popupLoadCallback();
        let self = this;

        this.mapProvider.whenMapLoads(function () {
            self.mapProvider.addMarkers('drrp_numbers', 'geojson', geojsonPath, layer, popupDataCallback)
        })
    }

    addMarkers(id, geojson, cluster = false, layer = {}) {
        let self = this;
        this.mapProvider.whenMapLoads(function () {
            self.mapProvider.addMarkers(id, 'geojson', geojson, layer, null, cluster)
        })
    }

    addClusteredMarkers(id, geojson, popupCallback, clustersLayer, clusterCountLayer, unClusteredPointLayer) {
        let self = this;
        this.mapProvider.whenMapLoads(function () {
            self.mapProvider.addClusteredMarkers(id, 'geojson', geojson, popupCallback, clustersLayer, clusterCountLayer, unClusteredPointLayer)
        })
    }

    addGeojson(id, geojson, layer = {}) {
        let self = this;
        this.mapProvider.whenMapLoads(function () {
            self.mapProvider.addPolygon(
                id, 'geojson', geojson, layer,
            )
        });
    }

    addLine(id, geojson, layer = {}) {
        let self = this;
        this.mapProvider.whenMapLoads(function () {
            self.mapProvider.addLine(
                id, 'geojson', geojson, layer,
            )
        });
    }

    drawInit(onCreate, onUpdate, onDelete) {
        this.mapProvider.initDrawControl(
            onCreate,
            onUpdate,
            onDelete,
            this.config.mapboxDraw.stylesOverride,
            this.config.mapboxDraw.stylePredefined,
        )
    }

    drawAdd(feature) {
        this.mapProvider.initDrawControl();
        this.mapProvider.draw.add(feature);
    }

    drawGet(featureID) {
        this.mapProvider.initDrawControl();
        this.mapProvider.draw.get(featureID);
    }

    drawDelete(featureID) {
        this.mapProvider.initDrawControl();
        this.mapProvider.draw.delete(featureID);
    }
};
