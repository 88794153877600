export class MapboxLabelDisplay {

    constructor(label, cssClass) {
        this.label = label;
        this.cssClass = cssClass;
    }

    onAdd(map) {
        this.map = map;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
        this.container.appendChild(this.init());
        return this.container;
    }

    onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }

    init() {
        let div = document.createElement('div');
        div.className = 'mapbox-ctrl-layers-layer ' + this.cssClass;
        div.id = this.label;
        return div
    }
}