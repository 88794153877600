import {MapProvider} from "../abstract";
import {extendObject} from "../../helpers/dictionary";
import {MapboxLayersControl} from "./layers-control";
import {MapboxLabelDisplay} from "./label-display";
import {MapboxDrawStyles} from "./draw";

const Polygon = 'polygon';
const Marker = 'marker';
const Line = 'line';


export class MapboxMapProvider extends MapProvider {

    constructor(access_token, options, geoLayers) {
        super();
        mapboxgl.accessToken = access_token;
        this.options = options;
        this.geoLayers = geoLayers;
        this.drawStyles = new MapboxDrawStyles()
    }

    initMap() {
        this.map = new mapboxgl.Map(
            this.options
        );
        this.whenMapLoads(this.onMapLoads());
        return this.map;
    }

    setIcons(icons = []) {
        let self = this;
        this.whenMapLoads(function () {
            icons.forEach(function (icon, i, icons) {
                self.map.loadImage(icon.src, function (error, image) {
                    if (error) throw error;
                    self.map.addImage(icon.id, image);
                })
            })
        });
    }

    whenMapLoads(func) {
        if (this.map.isStyleLoaded()) {
            func();
        } else {
            this.map.on('load', function () {
                func();
            });
        }
    }

    onMapLoads() {
        let self = this;
        return function () {
            self.localize();
            self.controls();
        }
    }

    initLayers() {
        this.map.addControl(new MapboxLayersControl(this.geoLayers), 'top-left');
    }

    useGeolocation() {
        this.map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }));
    }

    addSearch(searchPhrase) {
        let geoCoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,

        });
        this.map.addControl(geoCoder);
        if ((typeof searchPhrase === 'string' || searchPhrase instanceof String) && searchPhrase !== '') {
            geoCoder.query(searchPhrase);
        }
    }

    controls() {
        this.initLayers();
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(new mapboxgl.FullscreenControl());
    }

    localize() {
        this.map.setLayoutProperty('road-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('waterway-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('natural-line-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('natural-point-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('water-line-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('water-point-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('poi-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('transit-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('airport-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('settlement-subdivision-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('settlement-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('state-label', 'text-field', ['get', 'name']);
        this.map.setLayoutProperty('country-label', 'text-field', ['get', 'name']);
    }

    initDrawControl(onCreate = undefined, onUpdate = undefined, onDelete = undefined, stylesConfig = [], stylePredefined = undefined) {
        if (this.draw) {
            return
        }
        let config = {
            displayControlsDefault: false,
            controls: {
                polygon: true,
            },
        };
        if (stylePredefined && this.drawStyles[stylePredefined]){
            config.styles = this.drawStyles[stylePredefined]()
        }
        if (stylesConfig.length > 0) {
            config.styles = stylesConfig
        }

        this.draw = new MapboxDraw(config);
        this.map.addControl(this.draw);
        if (onCreate !== undefined) this.map.on('draw.create', onCreate);
        if (onUpdate !== undefined) this.map.on('draw.update', onUpdate);
        if (onDelete !== undefined) this.map.on('draw.delete', onDelete);
    }

    drawFeature(feature, onUpdate) {
        this.initDrawControl();
        if (typeof feature === 'object' && Object.keys(feature).length !== 0) {
            this.draw.add(feature);
        }
        this.map.on('draw.create', onUpdate);
        this.map.on('draw.update', onUpdate);
        this.map.on('draw.delete', onUpdate);
    }

    trashDrawing() {
        this.draw.trash();
    }

    addPolygon(id, sourceType, data, layerOverride, popupCallback, labelProperty) {
        this.addSource(id, sourceType, data);
        let layerID = this.addLayer(id, Polygon, popupCallback, layerOverride);

        if (labelProperty != null) {
            this.addLabelsForPolygonLayer(id, layerID, labelProperty)
        }

    }

    addLine(id, sourceType, data, layerOverride, popupCallback) {
        this.addSource(id, sourceType, data);
        this.addLayer(id, Line, popupCallback, layerOverride);
    }

    flyTo(coord, zoom = 12) {
        console.log(coord);
        this.map.flyTo({
            center: coord,
            zoom: zoom,
        })
    }

    addMarkers(id, sourceType, data, layerOverride, popupCallback) {
        this.addSource(id, sourceType, data);
        this.addLayer(id, Marker, popupCallback, layerOverride);
    }

    addClusteredMarkers(id, sourceType, data, popupCallback = null, clustersLayer = {}, clusterCountLayer = {}, unClusteredPointLayer = {}) {
        let self = this;
        let sourceOverride = {
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
        };
        this.addSource(id, sourceType, data, sourceOverride);

        // Clusters layer
        let clustersLayerId = id + "-clusters";
        this.map.addLayer(extendLayer({
            id: clustersLayerId,
            type: 'circle',
            source: id,
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': '#d49000',
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    15,
                    100,
                    30,
                    750,
                    40
                ]
            }
        }, clustersLayer));

        // clustered objects count
        let clustersLayerCountId = id + "-clusters-count";
        this.map.addLayer(extendLayer({
            id: clustersLayerCountId,
            type: 'symbol',
            source: id,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-size': 12
            }
        }, clusterCountLayer));

        // unClustered points display
        let unClusteredPointLayerId = id + "-un-clustered-point";
        this.map.addLayer(extendLayer({
            id: unClusteredPointLayerId,
            type: 'circle',
            source: id,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#d49000',
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#ff9800'
            }
        }, unClusteredPointLayer));

        // inspect a cluster on click
        this.map.on('click', clustersLayerId, function (e) {
            let features = self.map.queryRenderedFeatures(e.point, {
                layers: [clustersLayerId]
            });
            let clusterId = features[0].properties.cluster_id;
            self.map.getSource(id).getClusterExpansionZoom(
                clusterId,
                function (err, zoom) {
                    if (err) return;

                    self.map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );
        });

        // un clustered point on click
        this.map.on('click', unClusteredPointLayerId, function (e) {
            let coordinates = e['lngLat'];
            if (popupCallback !== null) {
                popupCallback(e.features[0].properties)
                    .then(result => {
                        if (result === '') {
                            return
                        }
                        new mapboxgl.Popup()
                            .setLngLat(coordinates)
                            .setHTML(result)
                            .addTo(self.map)
                    });
            }
        });
    }

    addSource(id, type, data, sourceOverride = {}) {
        this.removeSource(id);
        let sourceConfig = extendObject({
            type: type,
            data: data,
            generateId: true,
        }, sourceOverride);

        this.map.addSource(id, sourceConfig);
    }

    removeSource(id) {
        let layer_id = id + "_layer";
        let mapLayer = this.map.getLayer(layer_id);

        if (typeof mapLayer !== 'undefined') {
            this.map.removeLayer(layer_id).removeSource(id);
        }
    }

    addLayer(sourceID, type, popupCallback, layerOverride = {}) {
        let self = this;
        let layer = this.layerFactory(type);
        layer.id = sourceID + "_layer";
        layer.source = sourceID;
        layer = extendObject(layer, layerOverride);

        this.map.addLayer(layer);

        if (popupCallback === undefined) {
            return
        }

        this.map.on('click', layer.id, function (e) {
            console.log(e);
            let coordinates = e['lngLat'];
            if (popupCallback !== null) {
                popupCallback(e.features[0].properties)
                    .then(result => {
                        if (result === '') {
                            return
                        }
                        new mapboxgl.Popup()
                            .setLngLat(coordinates)
                            .setHTML(result)
                            .addTo(self.map)
                    });
            }
        });
        return layer.id;
    }

    layerFactory(type) {
        switch (type) {
            case 'marker':
                return {
                    "id": 0,
                    "type": "circle",
                    "source": '',
                    "paint": {
                        "circle-radius": 6,
                        "circle-color": "#fa0000",
                        "circle-opacity": 0.8,
                    },
                };
            case 'polygon':
                return {
                    "id": 0,
                    'type': 'fill',
                    "source": '',
                    "paint": {
                        "fill-color": "white",
                        "fill-outline-color": 'black',
                        "fill-opacity": 0.5,
                    },
                };
            case 'line':
                return {
                    "id": 0,
                    'type': 'line',
                    "source": '',
                    "paint": {
                        'line-color': 'black',
                        'line-width': 2
                    },
                }
        }
        throw new Error("Unknown layer type")
    }

    _createLabelControl(labelDisplayID, cssClass, position) {
        let e = document.getElementById(labelDisplayID);
        if (e == null) {
            this.map.addControl(new MapboxLabelDisplay(labelDisplayID, cssClass), position);
        }
    }

    addLabelsForPolygonLayer(sourceID, layerID, labelProperty) {
        let self = this;
        let hoveredFeature = {source: sourceID, id: null};
        let labelDisplayID = 'label-display-' + sourceID;
        console.log(hoveredFeature, labelDisplayID);
        this._createLabelControl(labelDisplayID, null, 'top-left');

        self.map.on("mousemove", layerID, function (e) {
            console.log(layerID, e);
            let features = e.features;
            console.log(features);
            if (features.length === 0) {
                return;
            }
            if (hoveredFeature.id !== null) {
                self.map.setFeatureState(hoveredFeature, {hover: false});
            }
            hoveredFeature.id = features[0].id;
            console.log(features);
            console.log(features[0].id, hoveredFeature);
            self.map.setFeatureState(hoveredFeature, {hover: true});
        });

        this.map.on('mousemove', layerID, function (e) {
            let property = e.features[0].properties[labelProperty];
            if (property === undefined) {
                return
            }
            document.getElementById(labelDisplayID).innerText = property;
            document.getElementById(labelDisplayID).style.display = 'block'
        });

        this.map.on("mouseleave", layerID, function () {
            if (hoveredFeature.id != null) {
                self.map.setFeatureState(hoveredFeature, {hover: false});
                hoveredFeature.id = null;
            }
            document.getElementById(labelDisplayID).innerText = null;
            document.getElementById(labelDisplayID).style.display = 'none'
        });
    }
}

function extendLayer(def = {}, custom = {}) {
    if (custom['paint'] !== undefined && def['paint'] !== undefined) {
        def['paint'] = {}
    }
    if (custom['layout'] !== undefined && def['layout'] !== undefined) {
        def['layout'] = {}
    }
    return extendObject(def, custom);
}