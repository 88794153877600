import * as toGeoJSON from '@mapbox/togeojson'
import {GeoJsonHelper} from "../../helpers/geo";


export class KmlComponent {

    constructor(kmlInputID) {
        this.kmlInputField = document.getElementById(kmlInputID);
    }

    handleInput() {
        let self = this;
        return new Promise(function (resolve, reject) {
            self.kmlInputField.addEventListener('change', self.handleInputEventCallback(resolve, reject), false);
        })
    }

    handleInputEventCallback(componentResolve, componentReject) {
        let self = this;
        return function (e) {
            e.preventDefault();
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onload = function (e) {
                self.convertAndSave(e.target.result, componentResolve, componentReject);
            };
            reader.readAsText(file);
        }
    }

    convertAndSave(kml, componentResolve, componentReject) {
        let geoJsonData = toGeoJSON.kml(new DOMParser().parseFromString(kml, 'text/xml'));
        let polygon = GeoJsonHelper.featureIsPolygon(geoJsonData);
        if (!polygon){
            componentReject(new Error("No polygon"));
        }
        componentResolve(geoJsonData);
    }
}
