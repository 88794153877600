import {fetch_retry} from "../../helpers/requests";


export class NumbersDisplay {

    constructor(geoJsonPath) {
    }

    popupLoadCallback() {
        return async function (properties) {
            let numberID = properties.id;
            console.log('number id', numberID);
            let url = '/monitor/numbers/' + numberID + '/map/';
            let options = {
                method: "GET",
                credentials: 'include',
            };
            return fetch_retry(url, options, 2)
                .then(response => response.text());
        }
    }
}
