export class MapProvider {

    constructor(access_token) {
    }

    initMap(id, center = [25, 50], zoom = 9, maxZoom = 22, style) {
    }

    setIcons (icons=[]){
    }

    initDrawControl() {
    }

    drawFeature(feature, onUpdate) {
    }

    trashDrawing() {
    }

    addMarker(marker) {
    }

    useGeolocation() {
    }
}