import {CadastralPolygon} from "./cadPolygon";
import {GeoJsonHelper} from "../../helpers/geo";

export class CadNumPolygonComponent {

    constructor(coordinates, resultInputField, polygonSaveField) {
        this.coordinates = coordinates;
        this.resultInputField = resultInputField;
        this.polygon = new CadastralPolygon(this.coordinates);
        this.polygonSaveField = polygonSaveField;
    }

    load() {
        return Promise.all([
            this.savePolygonIntoField(),
        ]);
    }

    savePolygonIntoField() {
        let field = document.getElementById(this.polygonSaveField);
        field.value = JSON.stringify(GeoJsonHelper.coordinatesAsMultiPolygon(field.value, this.coordinates));
        field.onchange();
    }
}
