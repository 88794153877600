import {fetch_retry} from "../../helpers/requests";


export class SurveyingPhotosDisplay {

    constructor(geoJsonPath) {
    }

    popupLoadCallback() {
        return async function (properties) {
            let photoID = properties.id;

            let url = '/monitor/surveying/photo/' + photoID + '/map/';
            let options = {
                method: "GET",
                credentials: 'include',
            };
            return fetch_retry(url, options, 2)
                .then(response => response.text());
        }
    }
}
