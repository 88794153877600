import {extendObject} from "./helpers/dictionary";

export class FeodalMapConfig {

    constructor(config) {
        this.defaultConfig = {
            'mapboxToken': undefined,
            'mapboxOptions': {
                'container': 'feodal-map',
                'center': [31.1828699, 48.383022],
                'zoom': 9,
                'maxZoom': 18,
                'preserveDrawingBuffer': false,
                'style': 'mapbox://styles/mapbox/satellite-streets-v11',
            },
            'mapboxDraw': {
                stylesOverride: [],
                // possible values: red
                stylePredefined: undefined,
            },
            'geoLayers': [
                {
                    'id': 'wms-google-layer',
                    'name': 'Супутник (google)',
                    'type': 'raster',
                    'layout': {
                        'visibility': 'none',
                    },
                    'source': {
                        'type': 'raster',
                        'tiles': [
                            'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
                        ],
                        'tileSize': 256
                    },
                },
                {
                    'id': 'wms-kadastr-layer',
                    'name': 'Кадастрова карта',
                    'type': 'raster',
                    'layout': {
                        'visibility': 'visible'
                    },
                    'source': {
                        'type': 'raster',
                        'tiles': [
                            'https://feodal.online/wms/?tiled=true&LAYERS=kadastr&STYLES=&FORMAT=image%2Fpng&service=WMS&request=GetMap&srs=EPSG%3A3857&layers=&styles=&format=image%2Fjpeg&transparent=false&version=1.1.1&width=256&height=256&bbox={bbox-epsg-3857}',
                        ],
                        'tileSize': 256
                    },
                },
            ],
            // Enables geolocation support
            'geolocation': false,
            // Enables search support
            'search': false,
            // set searchPhrase to automatically start search
            // for this phrase
            // 'search' config should be enabled
            'searchPhrase': false,
            'icons': [
                // Example:
                // {
                //     'id': undefined,
                //     'src': undefined,
                // }
            ],
            draw: {
                enable: false,
                features: [],
            },
            // @deprecated
            'numbersByDrawnPolygon': {
                'enable': false,
                'numberInputField': undefined,
                'savePolygonField': undefined,
            },
            // @deprecated
            'kml': {
                'enable': false,
                'inputFieldID': undefined,
            },
            // @deprecated
            'displayCadastralParsingInfo': {
                'enable': false,
                'type': 'geojson',
                'data': undefined,
                'layer': {},
            },
            // @deprecated
            'displayCadastralNumbers': {
                'enable': false,
                'type': 'geojson',
                'data': undefined,
                'layer': {},
            },
            // @deprecated
            'displaySurveyingPhotos': {
                'enable': false,
                'type': 'geojson',
                'data': undefined,
                'layer': {},
            },
            // @deprecated
            'displayDRRPNumbers': {
                'enable': false,
                'data': undefined,
                'layer': {},
            },
            'displayPolygon': {
                'enable': false,
                'id': undefined,
                'data': undefined,
                'labelProperty': undefined,
                'layer': {},
            },
            // @deprecated
            'loadingPolygonNumbers': {
                'enable': false,
                'inputFieldID': undefined,
                'stepFieldID': undefined,
                'outputFieldID': undefined,
                'loadButtonID': undefined,
                'stepDefaultValue': 200,
            },
            'layers': true,
        };

        return extendObject(this.defaultConfig, config)
    }
}
